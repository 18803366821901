export const ADMIN_EMAILS = ["developer@angadiworldtech.com"];
export const ADMIN_NUMBER = [8088404954,9705727488,8247326221];
export const USER_DATA_COLL_NAME = "user_data";
export const SERVICE_COLL_NAME = "service_data";
export const COMPANY_COLL_NAME = "company_data";
export const NOTIF_COLL_NAME = "notif_data";
export const USER_NOTIF_COLL_NAME = "user_notif_data";
export const POST_COLL_NAME = "posts_data";
export const COMMENT_COLL_NAME = "comment_data";
export const SCROLL_NOTIF = "scroll_notif";
export const DEFAULT_DOCUMENT_LIST = [
  "GST File",
  "PAN Card",
  "Company Inc. Cert",
];
export const NOTIF_LIMIT = 20;
