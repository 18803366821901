const ACTION_TYPES = {
    FETCH_POSTS: "FETCH_POSTS",
    LIKE_POST: "LIKE_POST",
    UNLIKE_POST: "UNLIKE_POST",
    FETCH_POST: "FETCH_POST",
    CREATE_POST: "CREATE_POST",
    EDIT_POST: "EDIT_POST",
    DELETE_POST: "DELETE_POST",
    SET_USER: "SET_USER",
    SET_TEMP_USER: "SET_TEMP_USER",
    SUBMIT_COMMENT: "SUBMIT_COMMENT",
    MARK_NOTIFICATION_READ: "MARK_NOTIFICATION_READ",
    LOGOUT: "LOGOUT",
    SET_ERROR: "SET_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",
    LOADING_UI: "LOADING_UI",
    STOP_LOADING_UI: "STOP_LOADING_UI",
    LOADING_USER: "LOADING_USER",
    LOADING_DATA: "LOADING_DATA",
};

export default ACTION_TYPES;
